import {
  DeliveryUnit,
  NotExpectedDeliveryUnitFormatError,
  UseDrops,
} from '@/features/tour-drops';
import {
  Receptacle,
  ReceptacleLabelEntry,
} from '@/features/tour-picking-receptacles';
import { DeliveryUnitType, UseTours } from '@/features/tours';
import { TemperatureClassList } from '@/features/products';
import { ErrorHandler } from '@/features/core/errors';
import { NoActiveDropError } from '../index';

export class PickingReceptaclesService {
  private sentIncorrectFormatError = false;

  constructor(
    private currentActiveDrop: UseDrops['currentActiveDrop'],
    private useTours: UseTours,
    private errorHandler: ErrorHandler,
  ) {}

  createReceptaclesFromDeliveryUnits(): Receptacle[] {
    if (!this.currentActiveDrop.value) {
      this.errorHandler.handle(new NoActiveDropError());
      return [];
    }

    const dropDeliveryUnits = this.useTours.getDropDeliveryUnits(
      this.currentActiveDrop.value,
    );

    const receptacleBoxes = dropDeliveryUnits.map((deliveryUnit) => {
      if (deliveryUnit.type !== DeliveryUnitType.bag) {
        return this.createUnknownReceptacle(deliveryUnit);
      }

      return this.createBagReceptacle(deliveryUnit);
    });

    const shouldAllowDuplicates = (receptacle: Receptacle) =>
      receptacle.temperatureClasses.includes(TemperatureClassList.freezer);

    const seenReferences = new Set<string>();

    return receptacleBoxes
      .filter((receptacle) => {
        if (shouldAllowDuplicates(receptacle)) {
          return true;
        }

        if (seenReferences.has(receptacle.reference)) {
          return false;
        }

        seenReferences.add(receptacle.reference);
        return true;
      })
      .sort(this.sortReceptacles);
  }

  private createUnknownReceptacle(deliveryUnit: DeliveryUnit): Receptacle {
    if (!this.sentIncorrectFormatError) {
      this.sentIncorrectFormatError = true;
      this.errorHandler.handle(new NotExpectedDeliveryUnitFormatError());
    }

    const receptacleLabel: ReceptacleLabelEntry[] = [
      {
        type: deliveryUnit.type,
        position: deliveryUnit.position,
        parentPosition: deliveryUnit.position,
      },
    ];

    return {
      reference: deliveryUnit.reference,
      barcode: deliveryUnit.code,
      label: receptacleLabel,
      temperatureClasses: deliveryUnit.temperatureClasses,
    };
  }

  private createBagReceptacle(deliveryUnit: DeliveryUnit) {
    const boxDeliveryUnit = this.useTours.getDeliveryUnitByReference(
      deliveryUnit.parentReference,
    );
    if (boxDeliveryUnit === undefined) {
      return this.createUnknownReceptacle(deliveryUnit);
    }

    const frameDeliveryUnit = this.useTours.getDeliveryUnitByReference(
      boxDeliveryUnit.parentReference,
    );
    if (frameDeliveryUnit === undefined) {
      return this.createUnknownReceptacle(deliveryUnit);
    }

    const receptacleLabel: ReceptacleLabelEntry[] =
      deliveryUnit.temperatureClasses.includes(TemperatureClassList.freezer)
        ? [
            {
              type: DeliveryUnitType.box,
              position: boxDeliveryUnit.position,
              parentPosition: frameDeliveryUnit.position,
            },
            {
              type: DeliveryUnitType.bag,
              position: deliveryUnit.position,
              parentPosition: boxDeliveryUnit.position,
            },
          ]
        : [
            {
              type: DeliveryUnitType.box,
              position: boxDeliveryUnit.position,
              parentPosition: frameDeliveryUnit.position,
            },
          ];

    return {
      reference: boxDeliveryUnit.reference,
      barcode: boxDeliveryUnit.code,
      label: receptacleLabel,
      temperatureClasses: deliveryUnit.temperatureClasses,
    };
  }

  private sortReceptacles = (a: Receptacle, b: Receptacle) => {
    const getBoxPosition = (receptacle: Receptacle) =>
      receptacle.label.find((label) => label.type === DeliveryUnitType.box)
        ?.position ?? '';
    const boxPositionA = getBoxPosition(a);
    const boxPositionB = getBoxPosition(b);
    return boxPositionA.localeCompare(boxPositionB, undefined, {
      numeric: true,
    });
  };
}
