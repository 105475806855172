<template>
  <button
    :class="{
      'primary-btn-outline': btnOutline,
      'primary-btn': !btnOutline,
      'primary-btn__active': active,
      'primary-btn_text': text,
      'primary-btn_round': icon,
      'primary-btn_plain': plain,
      'primary-btn-white': btnWhite,
      sm: sm,
    }"
    :disabled="disabled"
  >
    <span class="content">
      <slot />
      <img
        v-if="loading"
        src="@/assets/images/loading.gif"
        class="loading_img"
        alt="loading"
        data-e2e="loading"
      />
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    btnOutline: {
      type: Boolean,
      default: false,
    },
    btnWhite: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    plain: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 11px 20px;
  width: 100%;
  height: 48px;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  color: #212529;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: $primary-btn-radius;
  transition: all 0.3s ease-in-out;
  line-height: 1.5;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  user-select: none;
  user-select: none;
  flex-direction: row;
  vertical-align: middle;
}

.primary-btn {
  @extend .btn;
  color: $primary-btn-color;
  background: $primary-btn-bg;

  &:disabled,
  &.disabled {
    color: $btn-disabled;
    border-color: $btn-bg-disabled;
    pointer-events: none;
    background: $btn-bg-disabled;
  }

  &:active {
    background: darken($primary-btn-bg, 10);
  }

  &_round {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 44px;
    min-width: 44px;
    height: 44px;
    border-radius: 50%;

    &.sm {
      padding: 0;
      width: 36px;
      min-width: 36px;
      height: 36px;
    }

    &:active {
      color: #fff;
      background: $primary-btn-bg;
    }

    &.primary-btn__active {
      color: #fff;
      background: $primary-btn-bg;
    }
  }

  &_text {
    background: none;
    border: none;

    &:disabled,
    &.disabled {
      background: none;
    }
  }

  &_plain {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: none;
    border: none;

    &:active {
      background: none;
    }

    &:disabled,
    &.disabled {
      background: none;
    }
  }
}

.loading_img {
  margin-left: 8px;
  width: 24px;
  height: auto;
}

.primary-btn-white {
  @extend .btn;
  color: $primary-btn-bg;
  background-color: $primary-btn-color;
}

.primary-btn-outline {
  @extend .btn;
  padding: 7px 20px;
  color: $primary-btn-bg;
  border-color: $primary-btn-bg;

  &:disabled,
  &.disabled {
    color: $btn-disabled;
    border-color: $btn-disabled;
    pointer-events: none;
  }

  &:active,
  &.primary-btn__active {
    font-weight: bold;
  }
}

.content {
  display: flex;
  align-items: center;
  gap: 0 4px;
}
</style>
