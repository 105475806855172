import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInMinutes,
} from 'date-fns';
import { $t } from '@/i18n';

export function formatHours(date: Date): string {
  return date
    .toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    })
    .toLowerCase();
}
export function formatDate(date: Date, delimiter = '/'): string {
  const currentMonth = date.getMonth() + 1;
  const month = currentMonth > 9 ? `${currentMonth}` : `0${currentMonth}`;
  const currentDay = date.getDate();
  const day = currentDay > 9 ? `${currentDay}` : `0${currentDay}`;
  return `${day}${delimiter}${month}${delimiter}${date.getFullYear()}`;
}

export function formatDateFromYear(date: Date): string {
  return date.toISOString().split('T')[0];
}

export function formatDateTime(date: Date): string {
  return (
    formatDate(date, '.') +
    ' ' +
    date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    })
  );
}

export enum dateAmountType {
  DAYS,
  WEEKS,
  MONTHS,
  YEARS,
}

export function addDate(
  dt: Date,
  amount: number,
  dateType: dateAmountType,
): Date | 0 {
  switch (dateType) {
    case dateAmountType.DAYS:
      return addDays(dt, amount);
    case dateAmountType.WEEKS:
      return addWeeks(dt, amount);
    case dateAmountType.MONTHS:
      return addMonths(dt, amount);
    case dateAmountType.YEARS:
      return addYears(dt, amount);
  }
}
export function timeDiffInMinutes(
  start: Date,
  end: Date,
): { positiveFormattedDiff: string; timeDiff: number } {
  const minutesDifference = differenceInMinutes(start, end);
  const absoluteMinutes = Math.abs(minutesDifference);
  const hours = Math.floor(absoluteMinutes / 60);
  const remainingMinutes = absoluteMinutes % 60;

  // Custom formatting
  const formatted = $t('common.time-format.format', {
    hours: hours,
    minutes: remainingMinutes,
  });
  const shortFormatted = $t('common.time-format.short-format', {
    minutes: remainingMinutes,
  });

  return {
    positiveFormattedDiff: absoluteMinutes < 60 ? shortFormatted : formatted,
    timeDiff: minutesDifference,
  };
}
