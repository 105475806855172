import { RepositoryReadOptions } from '@/features/core/entity-repository';
import { OrderMetadata } from '../entities';

export interface OrderMetadataService {
  save(orderMetadata: OrderMetadata): Promise<void>;
  bulkSave(orderMetadata: OrderMetadata[]): Promise<void>;
  getById(id: string): Promise<OrderMetadata | undefined>;
  getAll(options: RepositoryReadOptions): Promise<OrderMetadata[] | undefined>;
  removeById(id: string): Promise<void>;
  removeByIds(ids: string[]): Promise<void>;
  clearAll(): Promise<void>;
  isOrderUnseen(metadata: OrderMetadata): boolean;
}

export const showAsUnseenMinutes = 10;
