export class UrlBuilderService {
  constructor(private origin = globalThis.location.origin) {
    if (!this.originRegex.test(this.origin)) {
      throw new Error('Not allowed origin');
    }
    this.integrationUrl = this.origin.replace(
      this.replacementRegex,
      '$1integration-api$2',
    );
    this.appOauthUrl = this.origin.replace(
      this.replacementRegex,
      '$1mock-server$2',
    );
    this.storeFrontApiUrl = this.origin.replace(
      this.replacementRegex,
      '$1api$2',
    );
  }

  private readonly originRegex =
    /https?:\/\/(.+\.aldi\.%store|lower%|.*\.(commerce|cfs)\.ci-aldi\.com|us1-proxy\.adobemc\.com|.*\.local|localhost:\d+|.*\.aldi-sued\.com|([a-z0-9-]+\.)dev\.na\.web\.aldi\.cx|([a-z0-9-]+\.)dev\.(cfs|cfswebcbp1|cfsweb%store|lower%1)\.azure\.aldicloud\.com|(author-p|publish-p)(31391|29434|41254|41255|41256|63855|63956|64453)-e[0-9]+\.adobeaemcloud\.(com|net)|(new\.aldi\.us))/;
  private readonly replacementRegex = /(https?:\/\/)[\w-]+([\w.-]+)(:\d+)?/;

  private readonly integrationUrl: string;
  private readonly appOauthUrl: string;
  private readonly storeFrontApiUrl: string;

  getHostUrl(): string {
    return process.env.VUE_APP_HOST_URL || this.origin;
  }

  getApiUrl(): string {
    return process.env.VUE_APP_API_URL || this.integrationUrl;
  }

  getStoreFrontApiUrl(): string {
    return process.env.VUE_APP_SEARCH_URL || this.storeFrontApiUrl;
  }

  getAppOauthUrl(): string {
    return process.env.VUE_APP_OAUTH_BASE_URL || this.appOauthUrl;
  }
}
