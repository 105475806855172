import { MultiPluginFactory } from '@/features/core/plugin';
import { ordersPlugin } from '@/features/orders';
import { toursPlugin } from '@/features/tours';
import { dropsPlugin } from '@/features/tour-drops';
import { oauthLoginConfigPlugin } from '@/features/login';
import { oauthPlugin } from '@/features/oauth';
import { productSearchConfigPlugin } from '@/features/product-search';
import { productsPlugin } from '@/features/products';
import { barcodeConfigPlugin, barcodeServicePlugin } from '@/features/barcode';
import { storageZonesPlugin } from '@/features/storageZone';
import { configurationPlugin } from '@/features/configuration';
import { merchantConfigPlugin } from '@/features/merchant';
import { userHelperServicePlugin, userPlugin } from '@/features/user';
import { pushNotificationPlugin } from '@/features/push-notification';
import { syncSchedulerPlugin } from '@/features/sync-scheduler';
import { serviceWorkerPlugin } from '@/features/service-worker';
import { wakeLockPlugin } from '@/features/wake-lock';
import { sessionStorageConfigPlugin } from '@/features/session-storage';
import { processedOrderPlugin } from '@/features/processed-order';
import { appCrashPlugin } from '@/features/app-crash';
import { dataSyncPlugin } from '@/features/data-sync';
import { dataFetchQueuePlugin } from '@/features/data-fetch-queue';
import { dataBackgroundSyncPlugin } from '@/features/data-background-sync';
import { imageCachingQueuePlugin } from '@/features/imageCachingQueue';
import { appBootCheckerPlugin } from '@/features/app-boot-checker';
import { routeProcessGuardPlugin } from '@/features/route-process-guard/plugins';
import { tourCrashPlugin } from '@/features/tour-crash';
import { driverAppRedirectPlugin } from '@/features/driver-app-redirect';
import { e2eTestHelperPlugin } from '@/features/e2e-test-helper';
import { clearNotificationConfigPlugin } from '@/features/clear-notification';
import { orderMetadataPlugin } from '@/features/orderMetadata';
import { deviceIdPlugin } from '@/features/device-id';
import { retrySynchronisationsPlugin } from '@/features/retry-synchronisation';
import { surveyPlugin } from '@/features/survey';
import { userReportsPlugin } from '@/features/user-reports';
import {
  loggerTransportersPlugin,
  visibilityChangeLogPlugin,
} from '@/features/core/logger';
import { devicePlugin } from '@/features/device-info';
import { reportsPlugin } from '@/features/reports';
import { failedTransmissionsPlugin } from '@/features/transmissions';
import { deliveryUnitPlugin } from '@/features/delivery-unit';
import { driverNotificationsPlugin } from './driver-notifications';
import { deviceFeedbackServicePlugin } from '@/features/device-feedback';
import { tourDocumentPlugin } from '@/features/tour-document';
import { healthMonitoringPlugin } from '@/features/health-monitoring';

export const featuresPlugin = MultiPluginFactory.with({
  userHelperServicePlugin,
  merchantConfigPlugin,
  storageZonesPlugin,
  imageCachingQueuePlugin,
  syncSchedulerPlugin,
  clearNotificationConfigPlugin,
  configurationPlugin,
  productsPlugin,
  ordersPlugin,
  driverNotificationsPlugin,
  toursPlugin,
  dropsPlugin,
  tourDocumentPlugin,
  userPlugin,
  serviceWorkerPlugin,
  oauthLoginConfigPlugin,
  productSearchConfigPlugin,
  oauthPlugin,
  barcodeConfigPlugin,
  pushNotificationPlugin,
  wakeLockPlugin,
  sessionStorageConfigPlugin,
  dataSyncPlugin,
  deliveryUnitPlugin,
  dataFetchQueuePlugin,
  dataBackgroundSyncPlugin,
  processedOrderPlugin,
  deviceIdPlugin,
  devicePlugin,
  loggerTransportersPlugin,
  appCrashPlugin,
  appBootCheckerPlugin,
  routeProcessGuardPlugin,
  tourCrashPlugin,
  driverAppRedirectPlugin,
  e2eTestHelperPlugin,
  barcodeServicePlugin,
  orderMetadataPlugin,
  retrySynchronisationsPlugin,
  surveyPlugin,
  userReportsPlugin,
  reportsPlugin,
  failedTransmissionsPlugin,
  deviceFeedbackServicePlugin,
  visibilityChangeLogPlugin,
  healthMonitoringPlugin,
});
