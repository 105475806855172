import { Order } from '@/features/orders';
import {
  OrderMetadata,
  orderMetadataServicePlugin,
} from '@/features/orderMetadata';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useOrderMetadata() {
  const checkAndSetOrdersMetadata = async (orders: Order[]) => {
    const orderMetadataService = orderMetadataServicePlugin.get();
    const metadataIds = (await orderMetadataService.getAll())?.map(
      (orderMetadata) => orderMetadata.id,
    );

    let newOrders = orders;
    if (metadataIds?.length) {
      newOrders = orders.filter((order) => !metadataIds.includes(order.id));
    }

    if (newOrders.length) {
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const ordersMetadata = newOrders.map((order) => {
        let isSeen = true;
        if (order.creationTime) {
          const orderCreationDate = new Date(order.creationTime).setHours(
            0,
            0,
            0,
            0,
          );
          isSeen = currentDate !== orderCreationDate;
        }
        return OrderMetadata.from({ id: order.id, seen: isSeen });
      });
      await orderMetadataService.bulkSave(ordersMetadata);
    }
  };

  return {
    checkAndSetOrdersMetadata,
  };
}
