import { TourDropAddress, TourOriginAddress } from '@/features/tours';

const navigateToMap = (
  address: TourDropAddress | TourOriginAddress,
  includeAddress2 = false,
): void => {
  const { address1, address2, zipCode, city } = address;
  const fullAddress = includeAddress2
    ? `${address1} ${address2} ${zipCode} ${city}`
    : `${address1} ${zipCode} ${city}`;
  const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    fullAddress,
  )}&travelmode=driving&dir_action=navigate`;
  window.open(url, '_blank');
};

export { navigateToMap };
