import { ComputedRef, Ref } from 'vue';
import { SurveyModel } from 'survey-core';

export enum SurveyAction {
  start = 'start',
  submit = 'submit',
  back = 'back',
  next = 'next',
}

export enum SurveyStatus {
  start = 'survey_start',
  main = 'survey_main',
  complete = 'survey_complete',
}

export type SurveyInfo = {
  surveyId: number;
  dropReference: string;
  customerFirstName?: string;
  customerLastName?: string;
  customerZipCode: string;
  customerCity: string;
  customerAddress1: string;
  customerAddress2: string;
};

export type PostDropSurveyAnswersData = {
  surveyId: number;
  dropReference: string;
  answers: Record<string, string>;
};

export interface UseSurvey {
  isStartScreen: ComputedRef<boolean>;
  isMainScreen: ComputedRef<boolean>;
  isCompleteScreen: ComputedRef<boolean>;
  processing: Ref<boolean>;
  postDropSurveyAnswers: (survey: PostDropSurveyAnswersData) => Promise<void>;
  applyAction: (action: SurveyAction) => Promise<void>;
  getSurveyLocale: () => string;
  surveyInfo: Ref<SurveyInfo | undefined>;
  setSurveyInfo: (newSurveyInfo: SurveyInfo) => void;
  showBackButton: ComputedRef<boolean>;
  showNextButton: ComputedRef<boolean>;
  showStartButton: () => boolean;
  surveyModel: ComputedRef<SurveyModel | undefined>;
  initSurvey: (surveyId: number) => Promise<void>;
}
