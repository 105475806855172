import {
  Order,
  OrderItem,
  OrderItemStatus,
  ordersServicePlugin,
} from '@/features/orders';
import { Ref, ref, watch, WatchStopHandle } from 'vue';
import { UseHandoverOrder, WeightToRestore } from '../types';
import { StatusTypes, TabVariants } from '@/features/ui';
import { Status } from '@/features/age-verification';
import { deepClone } from '@/utils/helpers/deepClone';
import { getArrayDiffWithDuplicates } from '@/utils/helpers/getArrayDiffWithDuplicates';
import { OrderWeight } from '@/features/orders';

const order: Ref<Order | null> = ref(null);
const weightToRestore = ref<WeightToRestore>({});

export function useHandoverOrder(): UseHandoverOrder {
  let watcher: WatchStopHandle | null = null;
  const currentTab = ref<TabVariants>(TabVariants.All);
  const loading = ref<boolean>(true);

  const updateOrderItem = (
    orderItem: Pick<OrderItem, 'id'>,
    quantityRejected: number,
    amountRejected = 0,
    fullyRejected = false,
  ) => {
    const orderItemId = orderItem.id;
    if (!order.value) {
      throw new Error(
        `Order value missing while updating item with ID "${orderItemId}"`,
      );
    }
    const existingItems = order.value.items.filter(
      (item) => item.id === orderItemId,
    );
    if (!existingItems.length) {
      throw new Error(
        `No existing order items with ID "${orderItemId}" found in order ${order.value.id}`,
      );
    }
    // Manually find item in order
    // This lets us not rely on `orderItem` being a direct reference
    const existingStagedItem = existingItems.find(
      (item) => item.status === OrderItemStatus.staged,
    );
    if (!existingStagedItem) {
      throw new Error(
        `Staged order item with ID "${orderItemId}" not found in order ${order.value.id}`,
      );
    }

    if (quantityRejected) {
      let existingRejectedItem = existingItems?.find(
        (item) => item.status === OrderItemStatus.rejected,
      );
      if (existingRejectedItem) {
        existingRejectedItem.quantity += quantityRejected;
        existingRejectedItem.amount += amountRejected;

        if (existingRejectedItem.id in weightToRestore.value) {
          const weightArray = [...weightToRestore.value[orderItem.id]];
          existingRejectedItem.weights = getArrayDiffWithDuplicates(
            existingStagedItem.weights as OrderWeight[],
            weightArray,
          );
        }
      } else {
        existingRejectedItem = deepClone(existingStagedItem);
        existingRejectedItem.status = OrderItemStatus.rejected;
        existingRejectedItem.quantity = quantityRejected;
        existingRejectedItem.quantityOriginal =
          existingStagedItem.quantityOriginal;
        existingRejectedItem.amount = amountRejected;
        existingRejectedItem.amountOriginal = existingStagedItem.amountOriginal;

        if (fullyRejected) {
          existingRejectedItem.weights = existingStagedItem.weights
            ? [...existingStagedItem.weights]
            : [];
          existingStagedItem.weights = [];
        } else if (existingRejectedItem.id in weightToRestore.value) {
          const itemWeightsToRestore = [
            ...weightToRestore.value[existingStagedItem.id],
          ];
          existingRejectedItem.weights = getArrayDiffWithDuplicates(
            existingStagedItem.weights as OrderWeight[],
            itemWeightsToRestore,
          );
        }
        order.value?.items.push(existingRejectedItem);
      }
    }

    delete existingStagedItem.rejectionReason;
    existingStagedItem.isRejected = true;
    // Prevent the quantity and amount from going negative
    existingStagedItem.quantity = Math.max(
      existingStagedItem.quantity - quantityRejected,
      0,
    );
    existingStagedItem.amount = Math.max(
      existingStagedItem.amount - amountRejected,
      0,
    );
  };

  const updateOrderWeightItem = (
    orderItem: OrderItem,
    weightBefore = <OrderWeight[]>[],
    status = StatusTypes.Default,
  ) => {
    if (!order.value) {
      return;
    }
    const currentItemIndex = order.value.items.findIndex(
      (item) =>
        item.status === OrderItemStatus.staged && item.id === orderItem.id,
    );

    if (order.value && currentItemIndex >= 0) {
      const countRejected =
        order.value.items[currentItemIndex].quantity - orderItem.quantity;
      const countAmountRejected =
        order.value.items[currentItemIndex].amount - orderItem.amount;

      if (status === StatusTypes.Discard) {
        order.value.items[currentItemIndex].weights = [...weightBefore];
      } else {
        order.value.items[currentItemIndex].weights = orderItem.weights;
      }

      if (orderItem.rejectionReason) {
        order.value.items[currentItemIndex].rejectionReason =
          orderItem.rejectionReason;
      }

      if (countRejected > 0 || countAmountRejected > 0) {
        updateOrderItem(
          order.value.items[currentItemIndex],
          countRejected,
          countAmountRejected,
        );
      }
    }
  };

  const applyOrderItem = (orderItem: OrderItem, quantity: number) => {
    const countRejected = orderItem.quantity - quantity;
    updateOrderItem(orderItem, countRejected);
  };

  const rejectOrderItem = (
    orderItem: OrderItem,
    quantity: number,
    amount = 0,
  ) => {
    updateOrderItem(orderItem, quantity, amount, true);
  };

  const loadOrder = async (id: string): Promise<void> => {
    const result = await ordersServicePlugin.get().getOrderById(id);

    if (result === null) {
      return;
    }

    order.value = result.value;

    if (order.value.ageVerification?.status === Status.Rejected) {
      currentTab.value = TabVariants.Rejected;
    }

    weightToRestore.value = order.value.items?.reduce(
      (acc: Record<string, OrderWeight[]>, item: OrderItem) => {
        if (
          (item.status === OrderItemStatus.staged ||
            item.status === OrderItemStatus.rejected) &&
          item.weights?.length
        ) {
          const savedWeight = Array.isArray(acc[item.id]) ? acc[item.id] : [];
          acc[item.id] = [...savedWeight, ...item.weights];
        }
        return acc;
      },
      {},
    );

    if (watcher) {
      watcher();
      watcher = null;
    }

    watcher = watch(result, async () => {
      const result = await ordersServicePlugin.get().getOrderById(id);

      if (result === null) {
        return;
      }

      order.value = result.value;
    });

    loading.value = false;
  };

  const restoreItem = (orderItem: OrderItem) => {
    if (!order.value) {
      return;
    }
    const existItem = order.value.items.find((item) => {
      return item.isRejected && item.id === orderItem.id;
    });
    const index = order.value.items.findIndex((item) => {
      return (
        item.status === OrderItemStatus.rejected && item.id === orderItem.id
      );
    });

    if (existItem) {
      existItem.isRejected = false;
      delete existItem.rejectionReason;
      existItem.quantity += order.value.items[index].quantity;
      existItem.amount += order.value.items[index].amount;
      if (orderItem.id in weightToRestore.value) {
        existItem.amount = weightToRestore.value[orderItem.id].reduce(
          (acc, item) => acc + item.weight,
          0,
        );

        existItem.weights = [...weightToRestore.value[orderItem.id]];
      }
    } else {
      order.value?.items.push({
        ...orderItem,
        status: OrderItemStatus.staged,
      });
    }

    if (index >= 0) {
      order.value?.items.splice(index, 1);
    }
  };

  return {
    order,
    loading,
    currentTab,
    loadOrder,
    restoreItem,
    rejectOrderItem,
    applyOrderItem,
    updateOrderWeightItem,
  };
}
