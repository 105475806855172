import { RepositoryReadOptions } from '@/features/core/entity-repository';
import { Storage } from '@/features/core/storage';
import { OrderMetadata } from '../entities';
import { OrderMetadataService } from '../types';

export class OrderMetadataServiceImplementation
  implements OrderMetadataService
{
  constructor(private storage: Storage) {}

  async save(orderMetadata: OrderMetadata): Promise<void> {
    await this.storage.save(orderMetadata);
  }

  async bulkSave(orderMetadata: OrderMetadata[]): Promise<void> {
    await this.storage.bulkSave(orderMetadata);
  }

  async getById(id: string): Promise<OrderMetadata | undefined> {
    return await this.storage.getById(OrderMetadata, {
      id,
    });
  }

  async getAll(options: RepositoryReadOptions = {}): Promise<OrderMetadata[]> {
    return await this.storage.getAll(OrderMetadata, options);
  }

  async removeById(id: string): Promise<void> {
    await this.storage.remove(
      OrderMetadata.from({
        id: id,
      }),
    );
  }

  async removeByIds(ids: string[]): Promise<void> {
    await this.storage.removeSeveral(OrderMetadata, { ids });
  }

  async clearAll(): Promise<void> {
    await this.storage.removeAll(OrderMetadata);
  }

  isOrderUnseen(metadata: OrderMetadata): boolean {
    return (
      !metadata.seen ||
      (!!metadata.showAsUnseenUntil && new Date() < metadata.showAsUnseenUntil)
    );
  }
}
