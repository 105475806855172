import { computed, Ref, ref, watch } from 'vue';
import {
  DeliveryType,
  DeliveryUnitType,
  Tour,
  TourActions,
  TourOriginAddress,
  TourStatus,
  UseTours,
} from '../types';
import { DeliveryUnit, TourDrop } from '@/features/tour-drops/types';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import { useDynamicDialog } from '@/features/ui/composables/useDynamicDialog';
import { toursServicePlugin } from '@/features/tours';
import { navigateToMap } from '@/features/ui/composables';
import { ordersServicePlugin } from '@/features/orders';
import { useOnline } from '@vueuse/core';
import { $t } from '@/i18n';
import { orderMetadataServicePlugin } from '@/features/orderMetadata';
import {
  isDriverNotAssignedError,
  NoActiveTourError,
  StartDeliveryFailedError,
} from '../errors';
import {
  CleveronTemperatureClassListOrder,
  HomeDeliveryTemperatureClassListOrder,
} from '@/features/products';
import {
  syncSchedulerExecutorPlugin,
  syncSchedulerServicePlugin,
} from '@/features/sync-scheduler';

const processing: Ref<boolean> = ref(false);
const tours: Ref<Tour[]> = ref([]);
const currentTour = ref<Tour | undefined>();
const recentlyDoneTours: Ref<Tour[]> = ref([]);
const resetRecentlyDoneTours = () => {
  recentlyDoneTours.value = [];
};
const addToRecentlyDoneTours = (newTour: Tour) => {
  if (!recentlyDoneTours.value.find((tour) => tour.id === newTour.id)) {
    recentlyDoneTours.value.push(newTour);
  }
};

export function useTours(): UseTours {
  const { confirm, close } = useDynamicDialog();
  const online = useOnline();

  const completedTours: Ref<Tour[]> = ref([]);
  const currentTours = computed<Tour[]>(() =>
    tours.value.filter(
      (tour) =>
        tour.status === TourStatus.wait_for_delivery ||
        tour.status === TourStatus.delivery_in_progress ||
        tour.status === TourStatus.heading_back_to_hub ||
        tour.status === TourStatus.car_clearance,
    ),
  );

  const openTours = computed<Tour[]>(() =>
    tours.value
      .filter(
        (tour) =>
          tour.status === TourStatus.open || tour.status === TourStatus.loading,
      )
      .sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime(),
      ),
  );

  const driverLicense = computed(() => {
    if (currentTours?.value.length) {
      return currentTours.value[0].vehicle.licensePlate;
    }
    if (openTours?.value.length) {
      return openTours.value[0].vehicle.licensePlate;
    }

    return '';
  });

  const notAllowedOffline = (tour: Tour) =>
    computed<boolean>(() => {
      if (online.value) {
        return false;
      }

      return [TourStatus.open, TourStatus.wait_for_delivery].includes(
        tour.status,
      );
    });

  const isAnyTourBlocked = computed<boolean>(() =>
    tours.value.some((tour) => notAllowedOffline(tour).value),
  );

  const originAddress = computed<TourOriginAddress | undefined>(
    () => tours.value.find((tour) => tour.originAddress)?.originAddress,
  );

  const loadingSingleTour = ref(false);
  const error = ref({
    name: '',
    message: '',
  });
  const getNextTour = (): Tour | null => {
    if (openTours.value.length >= 1) {
      return openTours.value[0];
    }
    return null;
  };

  const updateTour = (id: string, newTour: Tour): void => {
    try {
      loadingSingleTour.value = true;
      if (!tours.value) return;
      const index = tours.value.findIndex((tour) => tour.id == newTour.id);
      tours.value[index] = newTour;
      // TODO: temp implementation until we have update tour endpoint
    } catch (error) {
      errorPlugin.get().handle(error);
    } finally {
      loadingSingleTour.value = false;
    }
  };

  const getDropDeliveryUnits = (drop: TourDrop): DeliveryUnit[] => {
    if (!currentTour.value) return [];
    return currentTour.value.deliveryUnits?.filter((deliveryUnit) =>
      drop.deliveryUnitReferences.includes(deliveryUnit.reference),
    );
  };

  const getTemperatureClassList = (tour: Tour) => {
    if (tour.deliveryMode === DeliveryType.CLEVERON) {
      return CleveronTemperatureClassListOrder;
    }
    return HomeDeliveryTemperatureClassListOrder;
  };

  const getTemperatureClassOrderRank = (
    tour: Tour,
    { temperatureClasses }: DeliveryUnit,
  ): number => {
    const temperatureClassListOrder = getTemperatureClassList(tour);
    return Math.max(
      ...temperatureClasses.map(
        (tempClass) => temperatureClassListOrder[tempClass],
      ),
    );
  };

  const getFrames = (tour: Tour): DeliveryUnit[] => {
    return tour.deliveryUnits
      .filter((deliveryUnit) => deliveryUnit.type === DeliveryUnitType.frame)
      .sort(
        (a, b) =>
          getTemperatureClassOrderRank(tour, a) -
          getTemperatureClassOrderRank(tour, b),
      );
  };

  const getBoxes = (tour: Tour): DeliveryUnit[] => {
    return tour.deliveryUnits.filter(
      (deliveryUnit) => deliveryUnit.type === DeliveryUnitType.box,
    );
  };

  const getBags = (tour: Tour): DeliveryUnit[] => {
    return tour.deliveryUnits.filter(
      (deliveryUnit) => deliveryUnit.type === DeliveryUnitType.bag,
    );
  };

  const getDeliveryUnitByReference = (
    deliveryUnitReference: string,
  ): DeliveryUnit | undefined => {
    if (!currentTour.value) {
      throw new NoActiveTourError();
    }

    return currentTour.value.deliveryUnits.find(
      (deliveryUnit) => deliveryUnit.reference === deliveryUnitReference,
    );
  };

  const getDeliveryUnitByBarcode = (
    barcode: string,
  ): DeliveryUnit | undefined => {
    return currentTour?.value?.deliveryUnits?.find(
      (deliveryUnit) => deliveryUnit.code === barcode,
    );
  };

  const openHotspotHint = async (tour: Tour) => {
    currentTour.value = tour;

    await router.get().push({
      name: 'tour-hotspot',
      params: { tourId: currentTour.value.id },
    });
  };

  const startDelivery = async (tour: Tour) => {
    if (notAllowedOffline(tour).value) {
      return;
    }
    currentTour.value = tour;

    try {
      let pendingSyncs = await syncSchedulerServicePlugin.get().getPending();
      if (pendingSyncs.length > 0) {
        const syncExecutor = syncSchedulerExecutorPlugin.get();
        await syncExecutor.executeScheduler();
        pendingSyncs = await syncSchedulerServicePlugin.get().getPending();
      }
      if (pendingSyncs.length === 0) {
        await updateTourStatus(TourStatus.delivery_in_progress, false, true);
      } else {
        errorPlugin.get().handle(new StartDeliveryFailedError());
        return;
      }
    } catch (_) {
      errorPlugin.get().handle(new StartDeliveryFailedError());
      return;
    }

    await router.get().push({
      name: 'tour-drops',
      params: { tourId: currentTour.value.id },
    });
  };

  const continueDelivery = async (tour: Tour) => {
    currentTour.value = tour;

    await router.get().push({
      name: 'tour-drops',
      params: { tourId: currentTour.value.id },
    });
  };

  const finishTourChecklist = async () => {
    if (!currentTour.value) return;
    await router.get().push({
      name: 'tour-finish-complete',
      params: { tourId: currentTour.value.id },
    });
  };

  const finishCleveronTourChecklist = async () => {
    if (!currentTour.value) return;
    await router.get().push({
      name: 'cleveron-finish-complete',
      params: { tourId: currentTour.value.id },
    });
  };

  const finishTour = async () => {
    if (!currentTour.value) return;
    try {
      await updateTourStatus(TourStatus.completed);

      updateTour(currentTour.value.id, currentTour.value);
      completedTours.value.push(currentTour.value);
      if (currentTour.value) {
        addToRecentlyDoneTours(currentTour.value);
      }
      await router.get().push({
        name: 'tours',
      });
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };

  const finishLoadingTour = async () => {
    if (!currentTour.value) return;
    try {
      await updateTourStatus(TourStatus.wait_for_delivery);

      updateTour(currentTour.value.id, currentTour.value);
      await router.get().push({ name: 'tours' });
    } catch (error) {
      errorPlugin.get().handle(error);
    }
  };
  const startHeadingBack = async () => {
    if (!currentTour.value) return;
    await updateTourStatus(TourStatus.heading_back_to_hub);

    updateTour(currentTour.value.id, currentTour.value);
    navigateToMap(originAddress.value as TourOriginAddress, true);
  };
  const confirmDarkStoreArrival = async () => {
    if (!currentTour.value) return;
    await updateTourStatus(TourStatus.car_clearance);

    updateTour(currentTour.value.id, currentTour.value);
    await router.get().push({
      name: 'tour-finish-checklist',
      params: { tourId: currentTour.value.id },
    });
  };

  const continueCarClearance = async (tour: Tour) => {
    currentTour.value = tour;

    await router.get().push({
      name:
        currentTour.value.deliveryMode === DeliveryType.CLEVERON
          ? 'cleveron-finish-checklist'
          : 'tour-finish-checklist',
      params: { tourId: currentTour.value.id },
    });
  };

  const confirmCleveronDarkStoreArrival = async () => {
    if (!currentTour.value) return;
    await updateTourStatus(TourStatus.car_clearance);

    updateTour(currentTour.value.id, currentTour.value);
    await router.get().push({
      name: 'cleveron-finish-checklist',
      params: { tourId: currentTour.value.id },
    });
  };

  const startTour = async (tour: Tour) => {
    const notAllowed = notAllowedOffline(tour);
    const unwatchNotAllowedOffline = watch(notAllowed, () => {
      if (notAllowed.value) {
        close();
      }
    });

    const confirmed = await confirm({
      title: $t('components.tour-item.start-loading-title.text', {
        tourId: tour.id,
      }),
      confirmText: $t('components.tour-item.start-loading-confirm.text'),
    });

    unwatchNotAllowedOffline();
    if (confirmed && !notAllowed.value) {
      currentTour.value = tour;

      try {
        await updateTourStatus(TourStatus.loading, false, true);
        await toursServicePlugin.get().fetchAllTours();
        await router.get().push({
          name: 'tour-loading',
          params: { tourId: tour.id },
        });
      } catch (error) {
        if (isDriverNotAssignedError(error)) {
          const confirmed = await confirm({
            title: $t('components.tours-assignment.title.text', {
              tourId: tour.id,
            }),
            isTitleCentered: false,
            contentText: $t('components.tours-assignment.description.text'),
            confirmText: $t('components.tours-assignment.button-close.text'),
            showOnlyConfirm: true,
          });
          if (confirmed) {
            await toursServicePlugin.get().fetchAllTours();
            await ordersServicePlugin.get().fetchAllOrders();
            await orderMetadataServicePlugin.get().clearAll();
          }
        } else {
          errorPlugin.get().handle(error);
        }
      }
    }
  };

  const continueLoading = async (tour: Tour) => {
    const confirmed = await confirm({
      title: $t('components.tour-item.start-loading-title.text', {
        tourId: tour.id,
      }),
      confirmText: $t('components.tour-item.start-loading-confirm.text'),
    });
    if (confirmed) {
      await router.get().push({
        name: 'tour-loading',
        params: { tourId: tour.id },
      });
    }
  };

  const navigateToDarkstore = () => {
    if (!originAddress.value) return;
    navigateToMap(originAddress.value);
  };

  const applyAction = async (
    action: string,
    tour: Tour | null = null,
  ): Promise<void> => {
    if (!tour) return;

    processing.value = true;
    switch (action) {
      case TourActions.start_loading:
        await startTour(tour);
        break;
      case TourActions.continue_loading:
        await continueLoading(tour);
        break;
      case TourActions.finish_loading:
        await finishLoadingTour();
        break;
      case TourActions.open_hotspot_hint:
        await openHotspotHint(tour);
        break;
      case TourActions.start_delivery:
        await startDelivery(tour);
        break;
      case TourActions.continue_heading_back:
      case TourActions.continue_delivery:
        await continueDelivery(tour);
        break;
      case TourActions.start_heading_back:
        await startHeadingBack();
        break;
      case TourActions.continue_car_clearance:
        await continueCarClearance(tour);
        break;
      case TourActions.confirm_darkstore_arrival:
        await confirmDarkStoreArrival();
        break;
      case TourActions.confirm_cleveron_darkstore_arrival:
        await confirmCleveronDarkStoreArrival();
        break;
      case TourActions.finish_tour_checklist:
        await finishTourChecklist();
        break;
      case TourActions.finish_cleveron_tour_checklist:
        await finishCleveronTourChecklist();
        break;
      case TourActions.finish_tour:
      case TourActions.finish_cleveron_tour:
        await finishTour();
        break;
      case TourActions.route_guidance:
        navigateToDarkstore();
        break;
      default:
        break;
    }
    processing.value = false;
  };

  const updateTourStatus = async (
    status: TourStatus,
    scheduled = true,
    waitForSync = false,
  ): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    return new Promise<void>((resolve, reject) => {
      if (!currentTour.value) return;
      const prevStatus = currentTour.value.status;
      return toursServicePlugin
        .get()
        .updateTourStatus(currentTour.value, status, scheduled, waitForSync)
        .then(() => {
          if (!currentTour.value) return;
          currentTour.value.status = status;
          updateTour(currentTour.value.id, currentTour.value);
          resolve();
        })
        .catch((error) => {
          if (currentTour.value) {
            void toursServicePlugin
              .get()
              .revertTourStatus(currentTour.value, prevStatus);
          }

          return reject(error);
        });
    });
  };

  return {
    currentTours,
    openTours,
    tours,
    currentTour,
    loadingSingleTour,
    error,
    recentlyDoneTours,
    getFrames,
    getBoxes,
    getBags,
    updateTour,
    getDropDeliveryUnits,
    completedTours,
    driverLicense,
    originAddress,
    getNextTour,
    applyAction,
    resetRecentlyDoneTours,
    processing,
    notAllowedOffline,
    getDeliveryUnitByReference,
    getDeliveryUnitByBarcode,
    isAnyTourBlocked,
  };
}
